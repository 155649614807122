<template>
    <fragment>
        <v-popover
            auto-hide="false"
            trigger="hover"
            placement="left"
            v-if="isCreateDisabled"
            class="ml-auto"
        >
            <a class="btn btn-sm btn-alt-success px-15 my-5 mr-15 ml-auto">
                <i class="fa fa-plus mr-5"></i>
                Добавить сотрудника
            </a>
            <div slot="popover" style="padding: 0 10px;">
                <b>Внимание</b>
                <div>
                    Достигнут лимит по числу пользователей, пожалуйста,
                    перейдите на другой тарифный план
                </div>
            </div>
        </v-popover>

        <a v-else class="add-staff" @click="openModal">
            <i class="fa fa-plus mr-5"></i>
            Добавить сотрудника
        </a>
    </fragment>
</template>

<script>
    import session from '@/api/session'

    export default {
        name: 'AddStructureButton',
        data() {
            return {
                isCreateDisabled: false
            }
        },
        methods: {
            openModal() {
                const ChangeStaffModal = () => import(`../ChangeStaffModal`)
                this.$modal.show(
                    ChangeStaffModal,
                    {},
                    {
                        name: 'change-staff-modal',
                        scrollable: true,
                        height: 'auto',
                        adaptive: true,
                        maxWidth: 740,
                        width: '100%',
                        pivotY: 0,
                        clickToClose: false
                    }
                )
            }
        },
        async mounted() {
            let usersCount, current_rate, request

            request = await session.get(`/api/v1/users/count/`)
            usersCount = request.data.count

            request = await session.get(`/api/v1/rate/current_rate/`)
            current_rate = request.data

            if (
                usersCount >= 5 &&
                current_rate.is_free_rate &&
                !current_rate.exclude_domain
            ) {
                this.isCreateDisabled = true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .add-staff {
        border-radius: 50px;
        padding: 6px 22px;
        color: #000 !important;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        border: 2px solid #fbcf56;
        cursor: pointer;
        transition: background-color 200ms ease;
        
        &:hover {
            background-color: #fbcf56;
        }
    }
</style>
